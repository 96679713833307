import React from "react";
import linkedInSvg from "../img/linkedin.svg";

export default function ({ openModal }) {
  return (
    <div className="svg-footer">
      <a
        href="something"
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
      >
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="m0 241 121 120v-180zm0 0" fill="#5a5a5a" />
          <path d="m391 181v180l121-120zm0 0" fill="#444" />
          <path d="m91 0h330v421h-330zm0 0" fill="#ede9e8" />
          <path d="m256 0h165v421h-165zm0 0" fill="#dfd7d5" />
          <path
            d="m256 181c-49.5 0-90 40.5-90 90v30h180v-30c0-49.5-40.5-90-90-90zm0 0"
            fill="#73bcff"
          />
          <path
            d="m346 271v30h-90v-120c49.5 0 90 40.5 90 90zm0 0"
            fill="#0095ff"
          />
          <path
            d="m256 61c-33 0-60 27-60 60s27 60 60 60 60-27 60-60-27-60-60-60zm0 0"
            fill="#73bcff"
          />
          <path
            d="m256 181v-120c33 0 60 27 60 60s-27 60-60 60zm0 0"
            fill="#0095ff"
          />
          <path
            d="m0 512 144.953125-74.257812 66.046875-61.742188-211-135zm0 0"
            fill="#ffbd86"
          />
          <path
            d="m301 376 86.664062 75 124.335938 61v-271zm0 0"
            fill="#f6a96c"
          />
          <path d="m512 512h-512l211-136h90zm0 0" fill="#fed2a4" />
          <path d="m512 512h-256v-136h45zm0 0" fill="#ffbd86" />
        </svg>
      </a>

      <a
        href="https://github.com/shunchiang"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m512 257c0 120-84.101562 220.5-196 247.5l-30.601562-97.199219h-58.796876l-29.601562 97.199219c-111.898438-27-197-127.5-197-247.5 0-140.699219 115.300781-257 256-257s256 116.300781 256 257zm0 0"
            fill="#384949"
          />
          <path
            d="m512 257c0 120-84.101562 220.5-196 247.5l-30.601562-97.199219h-29.398438v-407.300781c140.699219 0 256 116.300781 256 257zm0 0"
            fill="#293939"
          />
          <path
            d="m181.277344 430.058594c-6.078125 0-12.011719-.867188-17.828125-2.578125-15.128907-4.46875-27.421875-14.546875-36.546875-29.914063-4.160156-7.015625-8.496094-11.878906-13.605469-15.308594-5.027344-3.382812-9.039063-4.671874-13.273437-4.363281l-2.636719-29.882812c11.117187-.953125 21.753906 2.0625 32.59375 9.316406 8.832031 5.902344 16.257812 14.0625 22.71875 24.914063 5.304687 8.921874 11.410156 14.152343 19.25 16.46875 8.804687 2.589843 17.941406 1.507812 29.632812-3.472657l11.808594 27.566407c-11.296875 4.835937-21.929687 7.253906-32.113281 7.253906zm0 0"
            fill="#ececf1"
          />
          <path
            d="m400.902344 287.300781c-10.503906 27.898438-36.902344 63.300781-103.800782 73.199219 8.699219 12.898438 19.199219 19.800781 18.898438 46.800781v97.199219c-19.199219 4.800781-39.300781 7.5-60 7.5s-39.800781-2.699219-59-7.5v-98.402344c0-26.699218 10.101562-34.199218 17.898438-45.597656-66.898438-9.902344-93.296876-45.300781-103.800782-73.199219-14.097656-37.203125-6.597656-83.402343 18.003906-112.800781.597657-.601562 1.5-2.101562 1.199219-3-11.402343-34.199219 2.398438-62.699219 3-65.699219 12.898438 3.898438 15-3.902343 56.699219 21.597657l7.199219 4.203124c3 1.796876 2.101562.597657 5.101562.597657 17.398438-4.800781 35.699219-7.5 53.699219-7.5 18.300781 0 36.300781 2.699219 54.597656 7.5l2.101563.300781s.597656 0 2.101562-.898438c51.898438-31.503906 50.097657-21.300781 64.195313-25.800781.300781 3 14.101562 31.796875 2.703125 65.699219-1.5 4.5 45 47.097656 19.203125 115.800781zm0 0"
            fill="#ececf1"
          />
          <path
            d="m400.902344 287.300781c-10.503906 27.898438-36.902344 63.300781-103.800782 73.199219 8.699219 12.898438 19.199219 19.800781 18.898438 46.800781v97.199219c-19.199219 4.800781-39.300781 7.5-60 7.5v-387.300781c18.300781 0 36.300781 2.699219 54.601562 7.5l2.097657.300781s.601562 0 2.101562-.898438c51.898438-31.503906 50.097657-21.300781 64.199219-25.800781.300781 3 14.101562 31.796875 2.699219 65.699219-1.5 4.5 45 47.097656 19.203125 115.800781zm0 0"
            fill="#e2e2e7"
          />
        </svg>
      </a>
      <a
        href="https://twitter.com/KevinCCCC"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 -47 512.00203 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m191.011719 419.042969c-22.140625 0-44.929688-1.792969-67.855469-5.386719-40.378906-6.332031-81.253906-27.457031-92.820312-33.78125l-30.335938-16.582031 32.84375-10.800781c35.902344-11.808594 57.742188-19.132813 84.777344-30.601563-27.070313-13.109375-47.933594-36.6875-57.976563-67.171875l-7.640625-23.199219 6.265625.960938c-5.941406-5.988281-10.632812-12.066407-14.269531-17.597657-12.933594-19.640624-19.78125-43.648437-18.324219-64.214843l1.4375-20.246094 12.121094 4.695313c-5.113281-9.65625-8.808594-19.96875-10.980469-30.78125-5.292968-26.355469-.863281-54.359376 12.476563-78.851563l10.558593-19.382813 14.121094 16.964844c44.660156 53.648438 101.226563 85.472656 168.363282 94.789063-2.742188-18.902344-.6875-37.148438 6.113281-53.496094 7.917969-19.039063 22.003906-35.1875 40.722656-46.691406 20.789063-12.777344 46-18.96875 70.988281-17.433594 26.511719 1.625 50.582032 11.558594 69.699219 28.742187 9.335937-2.421874 16.214844-5.011718 25.511719-8.511718 5.59375-2.109375 11.9375-4.496094 19.875-7.230469l29.25-10.078125-19.074219 54.472656c1.257813-.105468 2.554687-.191406 3.910156-.253906l31.234375-1.414062-18.460937 25.234374c-1.058594 1.445313-1.328125 1.851563-1.703125 2.417969-1.488282 2.242188-3.339844 5.035157-28.679688 38.867188-6.34375 8.472656-9.511718 19.511719-8.921875 31.082031 2.246094 43.96875-3.148437 83.746094-16.042969 118.230469-12.195312 32.628906-31.09375 60.621093-56.164062 83.199219-31.023438 27.941406-70.582031 47.066406-117.582031 56.847656-23.054688 4.800781-47.8125 7.203125-73.4375 7.203125zm0 0"
            fill="#25d9f8"
          />
          <path
            d="m476.855469 57.628906 19.074219-54.476562-29.25 10.078125c-7.9375 2.734375-14.277344 5.125-19.875 7.230469-9.296876 3.503906-16.171876 6.09375-25.507813 8.515624-19.121094-17.183593-43.1875-27.117187-69.703125-28.746093-24.988281-1.535157-50.195312 4.65625-70.984375 17.433593-9.5625 5.878907-17.902344 12.96875-24.839844 21.03125v374.824219c2.917969-.523437 5.8125-1.082031 8.679688-1.679687 46.996093-9.78125 86.558593-28.910156 117.582031-56.847656 25.070312-22.582032 43.96875-50.574219 56.164062-83.199219 12.894532-34.484375 18.289063-74.265625 16.042969-118.234375-.59375-11.570313 2.574219-22.605469 8.921875-31.078125 25.335938-33.832031 27.191406-36.625 28.679688-38.867188.371094-.566406.644531-.976562 1.703125-2.421875l18.457031-25.230468-31.230469 1.414062c-1.355469.058594-2.65625.148438-3.914062.253906zm0 0"
            fill="#00c0f1"
          />
        </svg>
      </a>

      <a
        href="https://www.linkedin.com/in/shun-chiang-dev/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={linkedInSvg} alt="linkedIn" />
      </a>
    </div>
  );
}
